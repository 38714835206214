import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { fetchGeoInfo, fetchIpInfo } from "../api/geo-api";
import { getResponseData } from "../api/utils";
import { DEFAULT_COUNTRY, DEFAULT_IP } from "../constants";
import { GeoInfoType, GetInfoResponseType } from "../types/global";
import { AllGetParams, DefinedDataParams, DefinedGetParams, RegisterFormValues, SendFormRequest } from "../types/RegisterFormType";
import getAnswersFrom from "./getAnswersForm";
import sha256 from 'sha256';

export const getCookie = (name: string): string | undefined => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}

const urlSearchParams = new URLSearchParams(window.location.search);
export const getValueFromData = (key: keyof DefinedDataParams, currFormName: string) => {
  const form = document.getElementById(currFormName);
  if (!form) return null;
  return form.dataset[key];
};
export const getValueFromSearch = (key: keyof DefinedGetParams) => urlSearchParams.get(key);
export const getValue = (key: keyof (DefinedDataParams | DefinedGetParams), currFormName: string) => getValueFromSearch(key) ?? getValueFromData(key, currFormName);

export const getLanguageFormParam = (currFormName: string) => (
  getValue('lf', currFormName)
  ?? window.navigator.language.split('-')[0]
  ?? 'en'
).toLowerCase();

export const getLanguageParam = (currFormName: string) => (
  getValueFromSearch('l')
  ?? getValueFromData('language', currFormName)
  ?? window.navigator.language.split('-')[0]
).toLowerCase();

export const getGeoData = async (currFormName: string): Promise<GeoInfoType> => {
  try {
    const geoInfo = await fetchGeoInfo()
    const ipInfo = await fetchIpInfo()

    const getInfoData = getResponseData<GetInfoResponseType>(geoInfo);
    const countryFromSearch = getValueFromSearch('c');
    const countryFromData = getValueFromData('country', currFormName);
    const currIp = getInfoData?.ipAddress;
    const currCountry = getInfoData?.countryCode;
    const country = countryFromSearch ?? countryFromData ?? currCountry ?? DEFAULT_COUNTRY;
    const ip = currIp;
    return { country, ip, sub5: ipInfo.data };
  } catch (error) {
    return { country: DEFAULT_COUNTRY, ip: DEFAULT_IP, sub5: DEFAULT_IP };
  }
}

export const getMainUrl = () => window.location.href.split('?')[0].replace('////', '/');

const getIsTestValue = (values: RegisterFormValues, currFormName: string): boolean => {
  const isTest = getValue('isTest', currFormName)
  let result: boolean = false;
  result = `${values.name}${values.surname}`.toLowerCase().includes('test') || `${values.name}${values.surname}`.toLowerCase().includes('тест');
  if (typeof isTest === 'string') {
    if (isTest === 'true') result = true;
    if (isTest === 'false') result = false;
  }
  return result;
}

const formatPhineNumber = (phone: string) => (phone[0] === '+' ? phone : `+${phone}`).replace(/[ ()-]/g, '');

export const createRequestParams = (
  values: RegisterFormValues,
  { country, ip, sub5 }: GeoInfoType,
  sessionId: string,
  currFormName: string,
) => {
  const url = getValue('url', currFormName) ?? getMainUrl();
  let requestParams: SendFormRequest = {
    ...values,
    params: {},
    phone: formatPhineNumber(values.phone),
    url,
    ip,
    answers: getAnswersFrom(currFormName),
    sessionId,
  };
  delete requestParams.code;
  delete requestParams.countryCode;
  const language = getLanguageParam(currFormName).toUpperCase();
  const referer = document.referrer || window.location.host;
  const affiliate = getValue('affiliate', currFormName);
  const source = getValue('source', currFormName);
  const partnerId = getValue('partnerId', currFormName);
  const isTest = getIsTestValue(values, currFormName);
  const landing = getValue('landing', currFormName);
  const redirect = getValue('redirect', currFormName);
  requestParams.language = language;
  requestParams.country = country;
  requestParams.isTest = isTest;

  if (affiliate) requestParams.affiliate = affiliate;
  if (referer) requestParams.referer = referer;
  if (source) requestParams.source = source;
  if (partnerId) requestParams.partnerId = partnerId;
  if (landing) requestParams.landing = landing;
  if (redirect) requestParams.redirect = redirect;

  const definedParams: (keyof Required<AllGetParams>)[] = ['country', 'language', 'isTest', 'affiliate', 'l', 'landing', 'c', 'referer', 'source', 'redirect', 'partnerId', 'lf'];
  const extraParams: SendFormRequest['params'] = {
    sub5,
  };
  const click_id = getCookie('_subid');
  if (click_id) extraParams.click_id = click_id;
  const paramsKeys = Array.from(urlSearchParams.keys()) as (keyof AllGetParams)[];
  paramsKeys.forEach((key) => {
    if (!definedParams.includes(key)) {
      const value = getValue(key as keyof (DefinedDataParams | DefinedGetParams), currFormName);
      if (value) extraParams[key] = value;
    }
  })
  requestParams.params = extraParams;
  return requestParams;
}

export const handleRedirect = (currFormName: string, redirectFromApi: string | null) => {
  const redirectParam = getValue('redirect', currFormName);
  if (!redirectParam && !redirectFromApi) return;
  if (redirectFromApi) {
    window.location.href = redirectFromApi;
    return;
  }
  urlSearchParams.delete('_lp');
  const params = urlSearchParams.toString();
  let newUrl = redirectParam;
  if (params) newUrl = `${newUrl}?${params}`;
  if (newUrl) {
    window.location.href = newUrl;
  }
}

export const filterObject = (obj: object) => (
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => {
    if (typeof v === 'object') return Object.keys(v).length;
    return v !== null && v !== undefined && v !== ''
  }))
);

export const generateRandomCode = () => (Math.floor(1000 + Math.random() * 9000)).toString();

export const FIELD_NAMES = {
  name: 'name',
  surname: 'surname',
  phone: 'phone',
  code: 'code',
  email: 'email',
}

export const fillFormErrors = (error: AxiosError<{ errors: string[] }>, helpers: FormikHelpers<RegisterFormValues>) => {
  const errors = error.response?.data.errors;
  const fieldKeys = Object.keys(FIELD_NAMES);
  errors?.forEach(item => {
    const currFieldKey = fieldKeys.find(key => item.includes(key));
    if (currFieldKey) helpers.setFieldError(currFieldKey, item)
  })
}

export const encodeFormData = (data: SendFormRequest) => (
  sha256([data.name, data.surname, data.email, data.phone, data.ip].join(''))
);
