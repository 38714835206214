import React, { HTMLProps } from 'react'
import { useField } from 'formik';
import cn from 'classnames';
import TextField from '../fields/TextField';
import FErrorMessage from './FErrorMessage';
import { SvgSymbolId } from '../Icon';

type Props = Modify<HTMLProps<HTMLInputElement>, {
  name: string;
  iconName?: SvgSymbolId;
}>

const FTextField = ({ name, className, ...props }: Props) => {
  const [field, meta] = useField(name);

  return (
    <div className={cn('tw-relative', className)}>
      <TextField
        {...props}
        {...field}
        onBlur={(e) => {
          if (props.onBlur) props.onBlur(e);
          field.onBlur(e);
        }}
        className="tw-w-[calc(100%-24px)]"
        outClassName={cn(
          'focus-visible:tw-border-slate-400 tw-shadow-[0_0_10px_0_rgba(0,0,0,0.3)] tw-border tw-rounded',
          {
          "tw-shadow-[0_0_10px_0_rgba(255,0,0,0.3)] tw-border-rose-400 tw-text-rose-400": !!meta.error && meta.touched,
        })}
      />
      <FErrorMessage name={name} />
    </div>
  )
}

export default FTextField;
