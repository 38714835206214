import cn from 'classnames';
import { FormikHandlers } from 'formik';
import { ChangeEvent, useState } from 'react';
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import Icon, { SvgSymbolId } from '../Icon';

type Props = Modify<PhoneInputProps, {
  value: string | null;
  onChange: (value: Props['value'], data: CountryData, event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FormikHandlers['handleBlur'];
  iconName?: SvgSymbolId;
  outClassName?: string;
}>

const PhoneField = ({ onChange, containerClass, inputClass, value, outClassName, iconName, ...props }: Props) => {
  const [inputValue, setInputValue] = useState(value);
  
  const handleChange = (value: string, data: CountryData, event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value);
    onChange(value, data, event);
  };

  return (
    <div className={outClassName}>
      <PhoneInput
        {...props}
        inputClass={cn(inputClass, "tw-p-3.5 tw-h-unset-im tw-w-full-im tw-br-none-im tw-text-black")}
        containerClass={cn(containerClass, "tw-text-base tw-rounded")}
        value={inputValue}
        inputProps={{
          name: 'phone',
        }}
        onChange={handleChange}
      />
      {iconName && <Icon symbolId={iconName} />}
    </div>
  );
};

export default PhoneField;
