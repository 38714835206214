import React from "react";
import cn from "classnames";

export type IconSizes = "10" | "12" | "14" | "16" | "20" | "24" | "32" | "36";

export type SvgSymbolId = "person" | "phone" | "mail";

interface Props {
  className?: string;
  rotating?: boolean;
  size?: IconSizes;
  symbolId: SvgSymbolId;
}

const Icon = ({ rotating, size = "24", symbolId, ...props }: Props): JSX.Element => {
  const className = cn("SvgIcon", props.className, {
    [`SvgIcon--s-${size || ""}`]: size,
    "SvgIcon--rotating": rotating,
  });
  return (
    <svg className={className}>
      <use xlinkHref={`#${symbolId}-symbol`}/>
    </svg>
  )
}

export default Icon;
