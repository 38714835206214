import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import EnJson from '../translations/en.json';
import RuJson from '../translations/ru.json';
import PlJson from '../translations/pl.json';
import ItJson from '../translations/it.json';
import HuJson from '../translations/hu.json';
import EsJson from '../translations/es.json';
import DeJson from '../translations/de.json';
import TrJson from '../translations/tr.json';
import RoJson from '../translations/ro.json';
import ArJson from '../translations/ar.json';

const fallbackLng = ["en", "ru", "pl", "it", "hu", "es", "de", "tr", "ro", "ar"];

export default i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng,
    lng: "en",
    debug: false,
    resources: {
      en: { ...EnJson },
      ru: { ...RuJson },
      pl: { ...PlJson },
      it: { ...ItJson },
      hu: { ...HuJson },
      es: { ...EsJson },
      de: { ...DeJson },
      tr: { ...TrJson },
      ro: { ...RoJson },
      ar: { ...ArJson },
    },
  });
