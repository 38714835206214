import React from 'react';

const Icons = () => (
  <svg style={{ display: 'none' }} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <symbol
        id="mail-symbol"
        viewBox="0 0 24 24"
        fill="currentcolor"
      >
        <path d="M12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12V13.45C22 14.4333 21.6627 15.271 20.988 15.963C20.3133 16.655 19.484 17.0007 18.5 17C17.9167 17 17.3667 16.875 16.85 16.625C16.3333 16.375 15.9 16.0167 15.55 15.55C15.0667 16.0333 14.5207 16.396 13.912 16.638C13.3033 16.88 12.666 17.0007 12 17C10.6167 17 9.43733 16.5123 8.462 15.537C7.48667 14.5617 6.99933 13.3827 7 12C7 10.6167 7.48767 9.43733 8.463 8.462C9.43833 7.48667 10.6173 6.99933 12 7C13.3833 7 14.5627 7.48767 15.538 8.463C16.5133 9.43833 17.0007 10.6173 17 12V13.45C17 13.8833 17.1417 14.25 17.425 14.55C17.7083 14.85 18.0667 15 18.5 15C18.9333 15 19.2917 14.85 19.575 14.55C19.8583 14.25 20 13.8833 20 13.45V12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20H16C16.2833 20 16.521 20.096 16.713 20.288C16.905 20.48 17.0007 20.7173 17 21C17 21.2833 16.904 21.521 16.712 21.713C16.52 21.905 16.2827 22.0007 16 22H12ZM12 15C12.8333 15 13.5417 14.7083 14.125 14.125C14.7083 13.5417 15 12.8333 15 12C15 11.1667 14.7083 10.4583 14.125 9.875C13.5417 9.29167 12.8333 9 12 9C11.1667 9 10.4583 9.29167 9.875 9.875C9.29167 10.4583 9 11.1667 9 12C9 12.8333 9.29167 13.5417 9.875 14.125C10.4583 14.7083 11.1667 15 12 15Z" />
      </symbol>

      <symbol
        id="person-symbol"
        viewBox="0 0 24 24"
        fill="currentcolor"
      >
        <path d="M18 13.7143C18.682 13.7143 19.336 13.9852 19.8183 14.4674C20.3005 14.9497 20.5714 15.6037 20.5714 16.2857V16.8986C20.5714 19.9646 16.9629 22.2857 12 22.2857C7.03714 22.2857 3.42857 20.0854 3.42857 16.8986V16.2857C3.42857 15.6037 3.69949 14.9497 4.18173 14.4674C4.66396 13.9852 5.31802 13.7143 6 13.7143H18ZM18 15H6C5.68052 15 5.37248 15.1189 5.13591 15.3336C4.89933 15.5484 4.75117 15.8435 4.72029 16.1614L4.71429 16.2857V16.8986C4.71429 19.1906 7.61914 21 12 21C16.2223 21 19.1614 19.1957 19.2814 17.0366L19.2857 16.8986V16.2857C19.2857 15.9662 19.1668 15.6582 18.9521 15.4216C18.7374 15.1851 18.4423 15.0369 18.1243 15.006L18 15ZM12 1.71429C12.6754 1.71429 13.3441 1.84732 13.9681 2.10577C14.592 2.36422 15.159 2.74304 15.6366 3.2206C16.1141 3.69816 16.4929 4.26511 16.7514 4.88907C17.0098 5.51303 17.1429 6.18178 17.1429 6.85715C17.1429 7.53252 17.0098 8.20128 16.7514 8.82524C16.4929 9.4492 16.1141 10.0161 15.6366 10.4937C15.159 10.9713 14.592 11.3501 13.9681 11.6085C13.3441 11.867 12.6754 12 12 12C10.636 12 9.32793 11.4582 8.36345 10.4937C7.39898 9.52923 6.85714 8.22112 6.85714 6.85715C6.85714 5.49318 7.39898 4.18508 8.36345 3.2206C9.32793 2.25613 10.636 1.71429 12 1.71429ZM12 3.00001C10.977 3.00001 9.99594 3.40639 9.27259 4.12974C8.54924 4.85309 8.14286 5.83417 8.14286 6.85715C8.14286 7.88013 8.54924 8.86121 9.27259 9.58456C9.99594 10.3079 10.977 10.7143 12 10.7143C13.023 10.7143 14.0041 10.3079 14.7274 9.58456C15.4508 8.86121 15.8571 7.88013 15.8571 6.85715C15.8571 5.83417 15.4508 4.85309 14.7274 4.12974C14.0041 3.40639 13.023 3.00001 12 3.00001Z" />
      </symbol>

      <symbol
        id="phone-symbol"
        viewBox="0 0 24 24"
        fill="currentcolor"
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M5.733 2.04299C6.95 0.832991 8.954 1.04799 9.973 2.40999L11.235 4.09399C12.065 5.20199 11.991 6.74999 11.006 7.72899L10.768 7.96699C10.7411 8.06692 10.7384 8.1718 10.76 8.27299C10.823 8.68099 11.164 9.54499 12.592 10.965C14.02 12.385 14.89 12.725 15.304 12.789C15.4082 12.8112 15.5162 12.8081 15.619 12.78L16.027 12.374C16.903 11.504 18.247 11.341 19.331 11.93L21.241 12.97C22.878 13.858 23.291 16.082 21.951 17.415L20.53 18.827C20.082 19.272 19.48 19.643 18.746 19.712C16.936 19.881 12.719 19.665 8.286 15.258C4.149 11.144 3.355 7.55599 3.254 5.78799C3.204 4.89399 3.626 4.13799 4.164 3.60399L5.733 2.04299ZM8.773 3.30899C8.266 2.63199 7.322 2.57799 6.79 3.10699L5.22 4.66699C4.89 4.99499 4.732 5.35699 4.752 5.70299C4.832 7.10799 5.472 10.345 9.344 14.195C13.406 18.233 17.157 18.354 18.607 18.218C18.903 18.191 19.197 18.037 19.472 17.764L20.892 16.351C21.47 15.777 21.343 14.731 20.525 14.287L18.615 13.248C18.087 12.962 17.469 13.056 17.085 13.438L16.63 13.891L16.1 13.359C16.63 13.891 16.629 13.892 16.628 13.892L16.627 13.894L16.624 13.897L16.617 13.903L16.602 13.917C16.5595 13.9558 16.514 13.9912 16.466 14.023C16.386 14.076 16.28 14.135 16.147 14.184C15.877 14.285 15.519 14.339 15.077 14.271C14.21 14.138 13.061 13.547 11.534 12.029C10.008 10.511 9.412 9.36899 9.278 8.50299C9.209 8.06099 9.264 7.70299 9.366 7.43299C9.42256 7.28126 9.5029 7.13949 9.604 7.01299L9.636 6.97799L9.65 6.96299L9.656 6.95699L9.659 6.95399L9.661 6.95199L9.949 6.66599C10.377 6.23899 10.437 5.53199 10.034 4.99299L8.773 3.30899Z" />
      </symbol>
    </defs>
  </svg>
);

export default Icons;
