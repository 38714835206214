import { QUIZ_FORM_NAME } from "../constants";
import { AnswerType } from "../types/RegisterFormType";
import { getValueFromData } from "./tools";

const getAnswersFromForm = () => {
  const result: AnswerType[] = [];
  const form = document.getElementById(QUIZ_FORM_NAME);
  if (!form) return [];

  const questionBlocks = form.querySelectorAll('[name="questionBlock"]')

  for (let i = 0; i < questionBlocks.length; i++) {
    const questionBlock = questionBlocks[i];
    const question = questionBlock.querySelector('[name="question"]');
    const answer = questionBlock.querySelector('input[data-name="answer"]:checked') as HTMLInputElement;
    const questionText = question?.textContent?.replace(/\s{2,}/g, '') ?? '';
    result.push({ question: questionText,  answer: answer?.value ?? '' })
  }
  return result
}

const getAnswersFromForm2 = () => {
  const questionsIds = ['mass2', 'mass3', 'mass4', 'mass5']
  const answersIds = ['myMessage1', 'myMessage2', 'myMessage3', 'myMessage4']
  const result: AnswerType[] = [];

  for (let i = 0; i < questionsIds.length; i++) {
    const question = document.getElementById(questionsIds[i]);
    const answer = document.getElementById(answersIds[i]);
    if (!question || !answer) continue;
    const questionText = question?.textContent?.replace(/\s{2,}/g, '') ?? '';
    const answerText = answer?.textContent?.replace(/\s{2,}/g, '') ?? '';
    result.push({ question: questionText,  answer: answerText })
  }
  return result
}

const getAnswersFromForm3 = () => {
  const result: AnswerType[] = [];
  const form = document.getElementById(QUIZ_FORM_NAME);
  
  if (!form) return [];
  const inputs = form.querySelectorAll('.answer-input')

  for (let i = 0; i < inputs.length; i++) {
    const questionBlock = inputs[i] as HTMLInputElement;
    const question = questionBlock.dataset.question;
    const answer = questionBlock.value;
    const questionText = question?.replace(/\s{2,}/g, '') ?? '';
    result.push({ question: questionText,  answer })
  }
  return result
}

const getAnswersFromForm4 = () => {
  const result: AnswerType[] = [];
  ((document as unknown as { questions: string[] }).questions).forEach((question, index) => {
    result.push({ question, answer: (document as unknown as { answers: string[] }).answers[index] })
  });
  return result
}

const getAnswersFrom = (currFormName: string) => {
  if (getValueFromData('ischatmode', currFormName) === 'true') {
    return getAnswersFromForm2();
  } else if (getValueFromData('ishiddeninputs', currFormName) === 'true') {
    return getAnswersFromForm3();
  } else if (getValueFromData('isquestionsfromdocument', currFormName) === 'true') {
    return getAnswersFromForm4();
  } else {
    return getAnswersFromForm();
  }
}

export default getAnswersFrom;
