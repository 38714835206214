import React from 'react'
import { ErrorMessage } from 'formik';

type Props = {
  name: string;
}

const FErrorMessage = ({ name }: Props) => (
  <ErrorMessage
    name={name}
    render={(errorMessage) => (
      <div className="fieldError tw-text-red-500 tw-bg-slate-50">{errorMessage}</div>
    )}
  />
)

export default FErrorMessage;
