import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { camelizeKeys, decamelizeKeys } from 'humps';
import { BASE_URL } from "../constants";

export const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    'Content-Type': 'application/json',
  },
  maxRedirects: 0,
});

const camelizeResponse = (response: AxiosResponse): AxiosResponse => {
  return { ...response, data: camelizeKeys(response.data) }
}

const decamelizeRequest = (request: InternalAxiosRequestConfig) => {
  return {
    ...request,
    data: decamelizeKeys(request.data),
  }
}

export const getResponseData = <T>(response: AxiosResponse<T>): T => response.data;

instance.interceptors.response.use(camelizeResponse);
