import App from './components/App';
import { REACT_APP_FORM_NAMES } from './constants';
import './styles/index.scss';
import "./utils/i18next";
import reportWebVitals from './utils/reportWebVitals';
import ReactDOM from 'react-dom';

REACT_APP_FORM_NAMES?.forEach(name => {
  const node = document.getElementById(name);
  if (node) {
    ReactDOM.render(
      <App currFormName={name} />,
      node,
    )
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
