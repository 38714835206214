import React, { ChangeEvent, HTMLProps, useState } from 'react'
import { useField, useFormikContext } from 'formik';
import cn from 'classnames';
import PhoneField from '../fields/PhoneField';
import FErrorMessage from './FErrorMessage';
import { CountryData, PhoneInputProps } from 'react-phone-input-2';
import { SvgSymbolId } from '../Icon';

type Props = Modify<HTMLProps<HTMLInputElement> & PhoneInputProps, {
  name: string;
  iconName?: SvgSymbolId;
}>

const FPhoneField = ({ name, className, ...props }: Props) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const [isOnFocus, setIsOnFocus] = useState(false);

  const onChange = (value: string | null, data: CountryData, event: ChangeEvent<HTMLInputElement>) => {
    if (value) {
      setFieldValue('phoneCode', `+${data.dialCode}`)
      setFieldValue('countryCode', data.countryCode.toUpperCase());
      setFieldValue('phoneNumber', value.replace(data.dialCode, ''))
    }

    field.onChange(event);
  }

  return (
    <div className={cn('tw-relative', className)}>
      <PhoneField
        {...props}
        {...field}
        onBlur={(e: React.FocusEvent<any>) => {
          if (props.onBlur) props.onBlur(e);
          field.onBlur(e);
          setIsOnFocus(false);
        }}
        onChange={onChange}
        containerClass={cn('tw-border tw-shadow-[0_0_10px_0_rgba(0,0,0,0.3)]', {
          'tw-shadow-[0_0_10px_0_rgba(255,0,0,0.3)] tw-border-rose-400': !!meta.error && meta.touched,
          'tw-border-transparent': !meta.error,
          'tw-border-slate-400': isOnFocus && !meta.error,
        })}
        onFocus={() => setIsOnFocus(true)}
        outClassName={cn({ "tw-text-rose-400": !!meta.error && meta.touched })}
      />
      <FErrorMessage name={name} />
    </div>
  )
}

export default FPhoneField;
