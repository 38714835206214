import React, { ChangeEvent, HTMLProps, useState } from 'react'
import cn from 'classnames';
import { FormikHandlers } from 'formik';
import Icon, { SvgSymbolId } from '../Icon';

type Props = Modify<HTMLProps<HTMLInputElement>, {
  value: string | number | null;
  onChange: FormikHandlers['handleChange'];
  iconName?: SvgSymbolId;
  outClassName?: string;
}>

const TextField = ({ value, onChange, className, outClassName, iconName, ...props }: Props) => {
  const [inputValue, setInputValue] = useState(value ?? "");
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  return (
    <div className={outClassName}>
      <input
        {...props}
        className={cn(className, 'tw-outline-0 tw-p-3.5 tw-w-full tw-text-base tw-rounded tw-text-black')}
        value={inputValue}
        onChange={handleChange}
      />
      {iconName && <Icon symbolId={iconName} />}
    </div>
  )
}

export default TextField;
