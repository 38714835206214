import { Form, Formik, FormikHelpers } from 'formik';
import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { postVerifyPhoneNumber, sendForm } from '../api/send-form';
import { BANNED_COUNTRIES, DEFAULT_COUNTRY, SEND_SMS_COUNTRIES, SEND_SMS_ENABLED, SOCKET_URL } from '../constants';
import useI18n from '../hooks/useI18n';
import { GeoInfoType } from '../types/global';
import { RegisterFormValues, SaveDataRequest } from '../types/RegisterFormType';
import {
  createRequestParams,
  FIELD_NAMES,
  fillFormErrors,
  filterObject,
  generateRandomCode,
  getValue,
  handleRedirect
} from '../utils/tools';
import FPhoneField from './formik-fields/FPhoneField';
import FTextField from './formik-fields/FTextField';
import Loader from './Loader';

interface Props {
  geoInfo: GeoInfoType;
  currFormName: string;
}

const RegisterForm = ({ geoInfo, currFormName }: Props) => {
  const t = useI18n();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowCodeField, setIsShowCodeField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [verificationCode, setVerificationCode] = useState<string | null>(null);

  const socket = io(SOCKET_URL ?? '', {
    autoConnect: false,
    withCredentials: false,
    transports: ['websocket'],
  });

  const bannedCountries = useMemo(() => {
    const noForm = getValue('banned_cntr', currFormName) ?? '';
    return [...(BANNED_COUNTRIES ?? []), ...noForm.split(',')]
  }, [BANNED_COUNTRIES, currFormName])

  const country = useMemo(
    () =>
      geoInfo.country === DEFAULT_COUNTRY
        ? 'us'
        : bannedCountries?.includes(geoInfo.country.toLowerCase())
          ? 'us'
          : geoInfo.country.toLowerCase(),
    [geoInfo]
  );

  const isShowConfirmation = useMemo(
    () => SEND_SMS_ENABLED && SEND_SMS_COUNTRIES?.some(item => country === item.toLowerCase()),
    [country],
  );

  useEffect(() => {
    setSessionId(uuidv4());
  }, []);

  const initialValues: RegisterFormValues = useMemo(
    () => ({
      name: '',
      surname: '',
      phone: '',
      code: '',
      email: '',
      phoneCode: '',
      phoneNumber: '',
      countryCode: country.toUpperCase(),
    }),
    [country]
  );

  const handleSubmit = useCallback(
    (values: RegisterFormValues, helpers: FormikHelpers<RegisterFormValues>) => {
      setIsLoading(true);
      const data = createRequestParams(
        values,
        geoInfo,
        sessionId,
        currFormName
      );
      return sendForm(data)
        .then((res) => {
          ReactPixel.track('Lead', filterObject(data));
          ReactGA.event("conversion", filterObject(data));
          setIsShowModal(true);
          handleRedirect(currFormName, res.data.redirect);
        })
        .catch((e) => fillFormErrors(e, helpers))
        .finally(() => setIsLoading(false));
    },
    [sessionId, geoInfo, currFormName]
  );

  const handleClose = () => setIsShowModal(false);

  const handleSendCode = (phone: string) => {
    const code = generateRandomCode();
    setVerificationCode(code);
    postVerifyPhoneNumber({
      messages: [
        {
          to: phone,
          source: 'sdk',
          body: `${t('base.verificationCode')}: ${code}`,
        },
      ],
    }).then(() => setIsShowCodeField(true));
  };

  const handleSaveData = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.target.value) return;
    const data: SaveDataRequest = {
      sessionId,
      url: `${window.location.origin}${window.location.pathname}`,
      [event.target.name]: event.target.value,
    };
    const landing = getValue('landing', currFormName);
    if (landing) data.landing = landing;
    socket.emit("applications", data)
  }

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, t('validation.short', { end: t('base.endIt'), name: t('fields.name') }))
      .max(50, t('validation.long', { end: t('base.endIt'), name: t('fields.name') }))
      .required(),
    surname: Yup.string()
      .min(2, t('validation.short', { end: t('base.endShe'), name: t('fields.surname') }))
      .max(50, t('validation.long', { end: t('base.endShe'), name: t('fields.surname') }))
      .required(),
    phone: Yup.string()
      .min(2, t('validation.short', { end: t('base.endHe'), name: t('fields.phone') }))
      .required()
      .test(
        'phone invalid',
        t('validation.wrongPhone'),
        (value) => {
          // console.log(isValidPhoneNumber(value), isPossiblePhoneNumber(value), validatePhoneNumberLength(value));

          return isValidPhoneNumber(value)
        },
      ),
    email: Yup.string()
      .email(t('validation.wrongEmail'))
      .required(),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values: { phone, code }, errors }) => {
          const isVerified = isShowConfirmation ? code === verificationCode : true;
          return (
            <Form className="tw-mx-5 tw-p-5 tw-shadow-md">
              <FTextField
                disabled={isLoading}
                name={FIELD_NAMES.name}
                iconName="person"
                placeholder={t('fields.name')}
                className="tw-mb-7"
                onBlur={handleSaveData}
              />
              <FTextField
                disabled={isLoading}
                name={FIELD_NAMES.surname}
                iconName="person"
                placeholder={t('fields.surname')}
                className="tw-mb-7"
                onBlur={handleSaveData}
              />
              <div className={isShowConfirmation ? "tw-flex tw-mb-7 tw-justify-between tw-items-center" : ""}>
                <FPhoneField
                  name={FIELD_NAMES.phone}
                  iconName="phone"
                  placeholder={t('fields.phone')}
                  className={isShowConfirmation ? "tw-w-full" : "tw-mb-7"}
                  country={country}
                  disabled={isLoading}
                  excludeCountries={bannedCountries}
                  onBlur={handleSaveData}
                />
                {isShowConfirmation && (
                  <Button
                    type="button"
                    className="tw-ml-3 tw-h-fit"
                    onClick={() => handleSendCode(phone)}
                    disabled={isLoading || !phone || !!errors.phone}
                  >
                    {t('base.verify')}
                  </Button>
                )}
              </div>
              {isShowCodeField && (
                <FTextField
                  disabled={isLoading}
                  type="code"
                  name={FIELD_NAMES.code}
                  placeholder={t('base.verificationCode')}
                  className="tw-mb-7"
                />
              )}
              <FTextField
                disabled={isLoading}
                type="email"
                name={FIELD_NAMES.email}
                iconName="mail"
                placeholder={t('fields.email')}
                className="tw-mb-7"
                onBlur={handleSaveData}
              />
              <Button
                type="submit"
                className="tw-w-full"
                disabled={isLoading || !isVerified}
                onClick={() => {
                  const ymCode = getValue('ym', currFormName);
                  if (ymCode && (window as FixMeLater).ym) {
                    (window as FixMeLater).ym(ymCode, 'reachGoal', 'TARGET_NAME');
                  }
                }}
              >
                {isLoading ? <Loader /> : t('base.registration')}
              </Button>
            </Form>
          )
        }}

      </Formik>
      <Modal show={isShowModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Body>{t('applicationAccepted')}</Modal.Body>
        </Modal.Header>
      </Modal>
    </>
  );
};

export default RegisterForm;
