export const QUIZ_FORM_NAME = 'quiz-lbdt';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const REACT_APP_FORM_NAMES = process.env.REACT_APP_FORM_NAMES?.split(' ');
export const IP_GEO_LOCATION_KEY = process.env.REACT_APP_IP_GEO_LOCATION_KEY;
export const DEFAULT_IP = '127.0.0.1';
export const DEFAULT_COUNTRY = '00';
export const SEND_SMS_ENABLED = process.env.REACT_APP_SEND_SMS_ENABLED === 'true';
export const SEND_SMS_COUNTRIES = process.env.REACT_APP_SEND_SMS_COUNTRIES?.split(';');
export const BANNED_COUNTRIES = process.env.REACT_APP_BANNED_COUNTRIES?.split(';');
export const VERIFY_PHONE_URL = process.env.REACT_APP_CLICKSEND_URL;
export const CLICKSEND_USERNAME = process.env.REACT_APP_CLICKSEND_USERNAME;
export const CLICKSEND_PASSWORD = process.env.REACT_APP_CLICKSEND_PASSWORD;
