import { CLICKSEND_PASSWORD, CLICKSEND_USERNAME, VERIFY_PHONE_URL } from "../constants";
import { SendFormRequest, SendFormRes, SendSmsBody } from "../types/RegisterFormType";
import { encodeFormData } from "../utils/tools";
import { getResponseData, instance } from "./utils"

export const sendForm = (data: SendFormRequest) => (
  instance.post<SendFormRes>('/api/v1/leads', data, {
    headers: {
      'x-service-token': encodeFormData(data),
    }
  }).then(getResponseData)
);

export const postVerifyPhoneNumber = (data: SendSmsBody) => (
  instance.post(
    VERIFY_PHONE_URL || '',
    data,
    {
      headers: {
        'Authorization': `Basic ${btoa(`${CLICKSEND_USERNAME}:${CLICKSEND_PASSWORD}`)}`
      }, baseURL: ''
    }
  )
)
