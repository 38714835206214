import { AxiosResponse } from "axios";
import { DEFAULT_COUNTRY, DEFAULT_IP, IP_GEO_LOCATION_KEY } from "../constants";
import { DefaultGetInfoResponseT, GetInfoResponseType } from "../types/global";
import { instance } from "./utils"

export const fetchGeoInfo = () => (
  instance.get<GetInfoResponseType>(
    `https://ipgeolocation.abstractapi.com/v1/?api_key=${IP_GEO_LOCATION_KEY}`,
    { baseURL: '' },
  ).catch(() => ({ data: { countryCode: DEFAULT_COUNTRY, ipAddress: DEFAULT_IP } } as AxiosResponse<DefaultGetInfoResponseT> ))
);

export const fetchIpInfo = () => (
  instance.get(
    'https://api.ipify.org/',
    { baseURL: '' },
  ).catch(() => ({ data: DEFAULT_IP }))
);
