import { IP_GEO_LOCATION_KEY } from "../constants";
import { GetInfoResponseType } from "../types/global";
import { instance } from "./utils"

export const fetchGeoInfo = () => (
  instance.get<GetInfoResponseType>(
    `https://ipgeolocation.abstractapi.com/v1/?api_key=${IP_GEO_LOCATION_KEY}`,
    { baseURL: '' },
  )
);

export const fetchIpInfo = () => (
  instance.get(
    'https://api.ipify.org/',
    { baseURL: '' },
  )
);
