import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { GeoInfoType } from '../types/global';
import { getGeoData, getLanguageFormParam, getLanguageParam, getMainUrl, getValue } from '../utils/tools';
import RegisterForm from './RegisterForm';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";
import { APP_VERSION } from '../constants';
import Icons from './Icons';

function App({currFormName}: { currFormName: string }) {
  const [geoInfo, setGeoInfo] = useState<GeoInfoType | null>(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    console.log('form-version', APP_VERSION);
    const pixelId = getValue('fbid', currFormName);
    const googleId = getValue('gid', currFormName);
    if (pixelId) {
      ReactPixel.init(
        pixelId,
        undefined,
        {
          debug: false,
          autoConfig: true,
        }
      );
      ReactPixel.pageView();
    }

    if (googleId) {
      ReactGA.initialize(googleId);
      ReactGA.send({ hitType: "pageview", page: getMainUrl() });
    }
  }, [currFormName]);

  useEffect(() => {
    const lf = getLanguageFormParam(currFormName);
    i18n.changeLanguage(lf);
    Yup.setLocale({
      mixed: {
        required: i18n.t('validation.required') ?? 'Required',
      },
    });
  }, [i18n, getLanguageParam, currFormName]);

  useEffect(() => {
    getGeoData(currFormName).then(setGeoInfo)
  }, [currFormName]);

  if (!geoInfo) return null;

  return (
    <div className="App">
      <Icons />
      <RegisterForm geoInfo={geoInfo} currFormName={currFormName} />
    </div>
  );
}

export default App;
